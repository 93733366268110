/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:b3d7aa1e-0f0b-4d15-89a5-8845ce78b7a7",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_jlxUtqfrI",
    "aws_user_pools_web_client_id": "ftu9disnocepne3an6972bndj",
    "oauth": {
        "domain": "authmoneytracker-dev.auth.us-east-1.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "http://localhost:3000/,https://trackingmyfinance.com/",
        "redirectSignOut": "http://localhost:3000/,https://trackingmyfinance.com/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS",
    "aws_appsync_graphqlEndpoint": "https://d7q4sghwxrdi7nmvdyqwlea5e4.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_user_files_s3_bucket": "moneytrackerbucket35929-dev",
    "aws_user_files_s3_bucket_region": "us-east-1"
};


export default awsmobile;
